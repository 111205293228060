<template>
  <div>
    <NuxtLayout name="custom" title="test" ref="layout">
      <div>
        <NuxtLoadingIndicator />
        <NuxtPage />
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const layout = ref();
</script>
<style lang="scss" scoped></style>
