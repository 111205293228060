export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:error", (error) => {
    console.error("启动错误:", error);
    // 可以在这里显示自定义错误页面、弹窗或上报错误信息
  });

  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    // 处理错误，例如上报到一个服务
    console.error("vue生命周期的错误:", error);
  };
});
