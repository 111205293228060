<template>
  <div>
    公共错误页
    <h2>{{ error?.statusCode }}</h2>
    <p>{{ error?.message }}</p>
    <p>{{ error?.description }}</p>
    <p>{{ error?.data }}</p>
    <button @click="handleError">清除错误</button>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  error: Object,
});

const handleError = () => clearError({ redirect: "/" });
</script>
