// plugins/fetchInterceptor.js
export default defineNuxtPlugin((nuxtApp) => {
  // 请求拦截器
  const requestInterceptor = (options:any) => {
    // 在这里添加全局的请求配置，比如添加认证头
    if (!options.headers) {
      options.headers = {};
    }
    // 假设使用了 token 认证
    const token = useCookie("token").value;
    if (token) {
      options.headers["Authorization"] = `Bearer ${token}`;
    }
    return options;
  };

  // 响应拦截器
  const responseInterceptor = (response:any) => {
    console.log(123)
    // 在这里处理全局的响应逻辑，比如错误处理
    if (!response.ok) {
      // 可以添加全局错误处理逻辑
      console.error(`请求失败: ${response.statusText}`);
      throw new Error(`请求失败: ${response.statusText}`);
    } else {
      console.log("请求成功");
    }
    return response;
  };

  // 封装的 useFetch 函数
  nuxtApp.provide("fetch", (url:string, options = {}) => {
    console.log(333)
    // 在发送请求前应用请求拦截器
    const modifiedOptions = requestInterceptor(options);

    // 使用原生的 useFetch 发送请求并应用响应拦截器
    return useFetch(url, modifiedOptions).then(responseInterceptor);
  });
});


