import { default as clientonlydvxu7chDFEMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/clientonly.vue?macro=true";
import { default as nuxterrorboundaryVEHSlQ9k6RMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/nuxterrorboundary.vue?macro=true";
import { default as nuxtimgppZxzFadZrMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/nuxtimg.vue?macro=true";
import { default as teleportwjOiat1q0iMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/teleport.vue?macro=true";
import { default as index2tDCqtQlK7Meta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/index.vue?macro=true";
import { default as useasyncdataFVFasEdk3cMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useasyncdata.vue?macro=true";
import { default as useCookielzhbFBJIBrMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useCookie.vue?macro=true";
import { default as useerrorYhfJ9e4oY9Meta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useerror.vue?macro=true";
import { default as usefetch6jtczGDbBrMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usefetch.vue?macro=true";
import { default as useHead0vrluBqw2cMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useHead.vue?macro=true";
import { default as usehydrationDEvmyHSJCdMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usehydration.vue?macro=true";
import { default as usestatelIKTngTP0ZMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usestate.vue?macro=true";
import { default as _91id_93NpELx4kAaiMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/goods/[id].vue?macro=true";
import { default as indexxrxLxyvnaEMeta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/index.vue?macro=true";
import { default as store3uLGJQZHA0Meta } from "/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/store.vue?macro=true";
export default [
  {
    name: "components-clientonly___en",
    path: "/components/clientonly",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/clientonly.vue").then(m => m.default || m)
  },
  {
    name: "components-clientonly___zh",
    path: "/zh/components/clientonly",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/clientonly.vue").then(m => m.default || m)
  },
  {
    name: "components-nuxterrorboundary___en",
    path: "/components/nuxterrorboundary",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/nuxterrorboundary.vue").then(m => m.default || m)
  },
  {
    name: "components-nuxterrorboundary___zh",
    path: "/zh/components/nuxterrorboundary",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/nuxterrorboundary.vue").then(m => m.default || m)
  },
  {
    name: "components-nuxtimg___en",
    path: "/components/nuxtimg",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/nuxtimg.vue").then(m => m.default || m)
  },
  {
    name: "components-nuxtimg___zh",
    path: "/zh/components/nuxtimg",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/nuxtimg.vue").then(m => m.default || m)
  },
  {
    name: "components-teleport___en",
    path: "/components/teleport",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/teleport.vue").then(m => m.default || m)
  },
  {
    name: "components-teleport___zh",
    path: "/zh/components/teleport",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/components/teleport.vue").then(m => m.default || m)
  },
  {
    name: "composables___en",
    path: "/composables",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/index.vue").then(m => m.default || m)
  },
  {
    name: "composables___zh",
    path: "/zh/composables",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/index.vue").then(m => m.default || m)
  },
  {
    name: "composables-useasyncdata___en",
    path: "/composables/useasyncdata",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useasyncdata.vue").then(m => m.default || m)
  },
  {
    name: "composables-useasyncdata___zh",
    path: "/zh/composables/useasyncdata",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useasyncdata.vue").then(m => m.default || m)
  },
  {
    name: "composables-useCookie___en",
    path: "/composables/useCookie",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useCookie.vue").then(m => m.default || m)
  },
  {
    name: "composables-useCookie___zh",
    path: "/zh/composables/useCookie",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useCookie.vue").then(m => m.default || m)
  },
  {
    name: "composables-useerror___en",
    path: "/composables/useerror",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useerror.vue").then(m => m.default || m)
  },
  {
    name: "composables-useerror___zh",
    path: "/zh/composables/useerror",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useerror.vue").then(m => m.default || m)
  },
  {
    name: "composables-usefetch___en",
    path: "/composables/usefetch",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usefetch.vue").then(m => m.default || m)
  },
  {
    name: "composables-usefetch___zh",
    path: "/zh/composables/usefetch",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usefetch.vue").then(m => m.default || m)
  },
  {
    name: "composables-useHead___en",
    path: "/composables/useHead",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useHead.vue").then(m => m.default || m)
  },
  {
    name: "composables-useHead___zh",
    path: "/zh/composables/useHead",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/useHead.vue").then(m => m.default || m)
  },
  {
    name: "composables-usehydration___en",
    path: "/composables/usehydration",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usehydration.vue").then(m => m.default || m)
  },
  {
    name: "composables-usehydration___zh",
    path: "/zh/composables/usehydration",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usehydration.vue").then(m => m.default || m)
  },
  {
    name: "composables-usestate___en",
    path: "/composables/usestate",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usestate.vue").then(m => m.default || m)
  },
  {
    name: "composables-usestate___zh",
    path: "/zh/composables/usestate",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/composables/usestate.vue").then(m => m.default || m)
  },
  {
    name: "goods-id___en",
    path: "/goods/:id()",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/goods/[id].vue").then(m => m.default || m)
  },
  {
    name: "goods-id___zh",
    path: "/zh/goods/:id()",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/goods/[id].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh",
    path: "/zh",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "store___en",
    path: "/store",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/store.vue").then(m => m.default || m)
  },
  {
    name: "store___zh",
    path: "/zh/store",
    component: () => import("/Users/linkz/Desktop/self/casegot-fullstack/www/web/src/pages/store.vue").then(m => m.default || m)
  }
]